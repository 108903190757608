<template>
  <div class="readme-article">
    <h1 id="办理退费">三、办理退费</h1>
    <h2 id="提交退费申请单">1.提交退费申请单</h2>
    <p>查看分期付款记录，可进行补缴，退费申请，添加分期付款等操作。</p>
    <p><img src="@/assets/img/finance/3-1.png" /></p>
    <h2 id="退费审批">2.退费审批</h2>
    <p>查看退费审批记录，可查看详情。</p>
    <p><img src="@/assets/img/finance/3-2.png" /></p>
    <h2 id="退费申请查询">3.退费申请查询</h2>
    <p>查看退费申请记录，可查看详情。</p>
    <p><img src="@/assets/img/finance/3-3.png" /></p>
    <h2 id="在线报考退费">4.在线报考退费</h2>
    <p>查看在线报考退费记录，可进行退款或者拒绝操作。</p>
    <p><img src="@/assets/img/finance/3-4.png" /></p>
  </div>
</template>

<script>
export default {
  name: "finance3-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>